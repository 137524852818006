import React, { Component } from "react";
import { connect } from "react-redux";
import { common, teamPortal } from "../../../actions";
import { bindActionCreators } from "redux";
import {
  ADD_ALERT,
  ADD_VALIDATION_ERROR_OBJ,
  CLEAR_STRIPE_FILED,
  UNLOAD_TEAM_PORTAL,
  UPDATE_FIELD_TEAM,
} from "../../../constants/types";
// } from "../../../constants/types";
import Kickoff from "../../Auth/Signup/Kickoff";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";
import Logo from "../../../assets/images/Black-logo.png";
import GiftForYou from "../../../assets/images/gift-for-you.jpeg";
import { GOOGLE_RECAPTCHA } from "../../../config/Auth";
import { checkError } from "../../Helper/model";
import { Elements } from "react-stripe-elements";
import StripeForm from "./StripeForm";
import moment from "moment";

const mapStateToProps = (state) => ({
  ...state.home,
  ...state.common,
  ...state.alert,
  ...state.teamPortal,
  ...state.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => bindActionCreators(teamPortal.fetchRate, dispatch)(),
  onPromoApply: () => bindActionCreators(teamPortal.applyPromo, dispatch)(),
  onClearStripe: () => dispatch({ type: CLEAR_STRIPE_FILED }),
  onUnLoad: () => dispatch({ type: UNLOAD_TEAM_PORTAL }),
  onSubmitTeamRegistration: (submitTeamForm, recaptchaToken) => {
    return bindActionCreators(teamPortal.submitTeamForm, dispatch)(
      submitTeamForm,
      recaptchaToken
    );
  },
  onAddValidationError: (data) => {
    dispatch({ type: ADD_VALIDATION_ERROR_OBJ, error: data });
  },
  onAddAlert: (alert) =>
    dispatch({
      type: ADD_ALERT,
      show: alert.show,
      message: alert.message,
      event: alert.event,
    }),
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_TEAM, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});
const defaultState = {
  step: 1,
  note: "",
};

class TeamPortal extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleChange = (ev) => {
      if (ev.target.name === "note") {
        const stringWithNewlines = ev.target.value.replace(/\n/g, "<br/>");
        this.setState({ note: stringWithNewlines });
        this.props.onChangeField(ev.target.name, ev.target.value);
      }
      this.props.onChangeField(ev.target.name, ev.target.value);
    };
    this.confirmGiftCard = () => {
      const {
        recipient_first_name,
        recipient_last_name,
        recipient_email,
        send_date,
        note,
      } = this.props;
      let model = {
        recipient_email: {
          value: recipient_email,
          key: "Recipient Email",
          validator: ["isEmail", "isGreaterThanFifty", "isNotEmpty"],
        },
        recipient_first_name: {
          value: recipient_first_name,
          key: "First name",
          validator: ["isGreaterThanFifty", "isNotEmpty"],
        },
        recipient_last_name: {
          value: recipient_last_name,
          key: "Last name",
          validator: ["isGreaterThanFifty", "isNotEmpty"],
        },
        send_date: {
          value: send_date,
          key: "Send Date",
          validator: ["isPastDate", "isNotEmpty"],
        },
        note: {
          value: note,
          key: "Note",
          validator: ["isNotEmpty"],
        },
      };
      let err = checkError(model);
      if (Object.keys(err).length > 0) {
        this.props.onAddValidationError(err);
        return;
      }
      this.handleStepChange(2);
      // this.captureError = this.captureError.bind(this);
    };
  }
  handleStepChange = (stepNumber) => {
    window.scroll(0, 0);
    this.setState({ step: stepNumber });
  };

  componentWillMount() {
    this.props.onLoad();
  }

  componentDidMount() {
    let coupon = this.props.location.hash;

    if (coupon) {
      this.props.onChangeField("coupon", coupon.substr(1));
    }
  }

  componentWillReceiveProps(nextProps) {}

  // componentWillUnmount() {
  //   this.props.onUnload();
  // }
  clearStripeForm = () => {
    this.props.onClearStripe();
    this.setState({ users: 1, totalCharge: this.props.rate.new_rate });
  };

  handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input value matches the pattern \d{2}-\d{2}-\d{4}
    if (/^\d{2}-\d{2}-\d{4}$/.test(inputValue)) {
      this.setState({ inputValue });
    } else {
      this.setState({ inputValue: "" });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="gift-grid">
          <div className="gift-grid-column-left">
            <div className="container-gift-grid">
              <div className={"gift-content-container"}>
                <div className="gift-logo-div">
                  <div className="gift-logo-sm">
                    <a href="/">
                      <img className="img-fluid" src={Logo} />
                    </a>
                  </div>
                </div>
                {this.state.step === 1 ? (
                  <div className="gift-text-div">
                    <span className="gift-text-lg">
                      Give the gift of peace and positivity.
                    </span>
                    <span className="redeem-text-sm">
                      Change someone's life with the perfect gift, an Unplug app
                      subscription.
                    </span>
                    <div className="gift-for-you">
                      <img
                        src={GiftForYou}
                        alt="A gift for you with unplug logo"
                      />
                    </div>
                    <div className="gift-subscription">
                      <span className="gift-subscription-header">
                        1-year subscription
                      </span>
                      <span className="gift-subscription-text">
                        ${this.props.rate.new_rate} USD. One-time payment.
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="gift-text-div">
                      <span className="gift-text-lg">
                        {this.props.recipient_first_name}’s digital gift card
                      </span>
                      <span className="gift-text-sm">Preview</span>
                      <div className="gift-for-you">
                        <img
                          src={GiftForYou}
                          alt="A gift for you with unplug logo"
                        />
                      </div>
                      <div className="gift-text-note">
                        <div
                          dangerouslySetInnerHTML={{ __html: this.state.note }}
                        />
                      </div>
                      <div className="gift-text-details">
                        <span>
                          Send date:{" "}
                          {moment(this.props.send_date, "YYYY-MM-DD")
                            .format("MM-DD-YYYY")
                            .replace("/-/g", "/")}
                        </span>
                        <span>Email: {this.props.recipient_email}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="gift-grid-column-right gift-grid-form-background">
            <div className="container-gift-grid-form">
              <div className="gift-form-steps">
                <div
                  className={`gift-form-step ${
                    this.state.step === 1 ? "" : "gift-form-step-disabled"
                  }`}
                  onClick={() => this.handleStepChange(1)}
                >
                  <div
                    className={`gift-form-step-number ${
                      this.state.step === 1
                        ? ""
                        : "gift-form-step-number-disabled"
                    } `}
                  >
                    1
                  </div>
                  <div className="gift-form-step-name">Personalize</div>
                </div>
                <div className="gift-form-step-line"></div>
                <div
                  className={`gift-form-step ${
                    this.state.step === 2 ? "" : "gift-form-step-disabled"
                  }`}
                  onClick={this.confirmGiftCard}
                >
                  <div
                    className={`gift-form-step-number ${
                      this.state.step === 2
                        ? ""
                        : "gift-form-step-number-disabled"
                    } `}
                  >
                    2
                  </div>
                  <div className="gift-form-step-name">Your Details</div>
                </div>
              </div>
              {this.state.step === 1 && (
                <>
                  <div className="gift-form">
                    <span className="gift-form-header">
                      Recipient’s Details
                    </span>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">First Name</span>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="recipient_first_name"
                        onChange={this.handleChange}
                        value={this.props.recipient_first_name}
                      />
                      {this.props.checkIfValidationError(
                        "recipient_first_name"
                      ) && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError(
                            "recipient_first_name"
                          )}
                        </span>
                      )}
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Last Name</span>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="recipient_last_name"
                        onChange={this.handleChange}
                        value={this.props.recipient_last_name}
                      />
                      {this.props.checkIfValidationError(
                        "recipient_last_name"
                      ) && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError(
                            "recipient_last_name"
                          )}
                        </span>
                      )}
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Email</span>
                      <input
                        type="text"
                        placeholder="Email"
                        name="recipient_email"
                        onChange={this.handleChange}
                        value={this.props.recipient_email}
                      />
                      {this.props.checkIfValidationError("recipient_email") && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError("recipient_email")}
                        </span>
                      )}
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Send Date</span>
                      <input
                        type="date"
                        className={
                          this.props.send_date ? "date-input--has-value" : ""
                        }
                        placeholder="DD-MM-YYYY"
                        name="send_date"
                        min={moment().format("YYYY-MM-DD")}
                        onChange={this.handleChange}
                        value={this.props.send_date}
                      />
                      {this.props.checkIfValidationError("send_date") && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError("send_date")}
                        </span>
                      )}
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Add a note</span>
                      <textarea
                        type="textarea"
                        placeholder="Add a note (255 characters)"
                        maxLength={255}
                        rows={"2"}
                        name="note"
                        onChange={this.handleChange}
                        value={this.props.note}
                      />
                      {this.props.checkIfValidationError("note") && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError("note")}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="gift-form-confirm-div">
                    <button
                      className="gift-form-confirm"
                      onClick={this.confirmGiftCard}
                    >
                      <span className="gift-form-confirm-text">
                        Confirm Gift Card
                      </span>
                    </button>
                  </div>
                </>
              )}
              {this.state.step === 2 && (
                <>
                  <Elements>
                    <StripeForm
                      {...this.props}
                      clearStripeForm={this.clearStripeForm.bind(this)}
                    />
                  </Elements>
                  {/* <div className="gift-form">
                    <div className="gift-form-step2">
                      <span className="gift-form-header">Set up payment</span>
                      <span className="gift-form-subheader">
                        One-time payment for 1-year plan:
                      </span>
                      <div className="gift-form-pricing-box">
                        <span className="gift-form-pricing">$69.99</span>
                      </div>
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Your Email</span>
                      <input
                        type="text"
                        placeholder="Email"
                        name="senderEmail"
                        onChange={this.handleChange}
                        value={this.props.senderEmail}
                      />
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Full Name on Card</span>
                      <input
                        type="text"
                        placeholder="Full Name on Card"
                        name="card_holder"
                        onChange={this.handleChange}
                        value={this.props.card_holder}
                      />
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Card Number</span>
                      <input
                        type="text"
                        placeholder="XXXX XXXX"
                        name="card_number"
                        onChange={this.handleChange}
                        maxLength={16}
                        value={this.props.card_number}
                      />
                    </div>
                    <div className="gift-form-inputs-div">
                      <div className="gift-form-inputs">
                        <span className="gift-form-label">Expiry Date</span>
                        <input
                          type="date"
                          placeholder="XXXX XXXX"
                          name="expiry"
                          onChange={this.handleChange}
                          value={this.props.expiry}
                        />
                      </div>
                      <div className="gift-form-inputs">
                        <span className="gift-form-label">CVC</span>
                        <input
                          type="text"
                          placeholder="XXXX XXXX"
                          name="cvc"
                          onChange={this.handleChange}
                          value={this.props.cvc}
                        />
                      </div>
                    </div>
                    <div className="gift-form-inputs-div">
                      <div className="gift-form-inputs">
                        <span className="gift-form-label">Promo Code</span>
                        <input
                          type="text"
                          name="promo_code"
                          onChange={this.handleChange}
                          value={this.props.promo_code}
                        />
                      </div>
                      <div className="gift-form-promo-btn-container">
                        <div className="gift-form-promo-btn">
                          <span>Apply</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="gift-form-footer">
                    <button
                      className="gift-form-confirm"
                      onClick={this.submitForm()}
                    >
                      <span className="gift-form-confirm-text">
                        Confirm Gift Card
                      </span>
                    </button>
                    <div className="gift-form-footer-text">
                      By purchasing an Unplug gift card, you agree to our{" "}
                      <a href="">Terms & Conditions.</a>
                    </div>
                  </div>
                  <div className="gift-form-help">
                    <span>Need Help?</span>
                    <a className="gift-form-help-link" href="">
                      Contact Us
                    </a>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamPortal)
);
