import {combineReducers} from 'redux';
import auth from './auth';
import alert from './alert';
import teamPortal from './teamPortal';
import home from "./home";
import common from './common';
import validationError from './validationError';
import {connectRouter} from 'connected-react-router';

const rootReducer = (history) => combineReducers({
	auth,
	alert,
	teamPortal,
	validationError,
	home,
	common,
	router: connectRouter(history)
});

export default rootReducer;