import React, { Component } from "react";
import logo from "../../../../assets/images/Black-logo.png";
import yogaBanner from "../../../../assets/images/yoga-banner.jpeg";
import appStore from "../../../../assets/images/app-store.png";
import playStore from "../../../../assets/images/play-store.png";
import Exclamation from "../../../../assets/images/icon-exclamation.svg";
export default class Kickoff extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="gift-grid">
          <div className="gift-grid-column-left">
            <div className="image-container">
              <img src={yogaBanner} alt="" class="yoga-image"></img>
            </div>
            <img src={logo} alt="" className="unplug-logo-redeem"></img>

            <div className=""></div>
          </div>
          <div className="gift-grid-column-right">
            <div className="right-confirmation-redeem">
              <div className="right-confimation-redeem-container">
                <div className="confirmation-unplug-detils">
                  <h2>Welcome to Unplug</h2>
                  <span>
                    For the full Unplug experience, download our mobile app and
                    login.
                  </span>
                </div>
                <div>
                  <div className="right-bubble">
                    <img
                      className="exclamation-icon"
                      src={Exclamation}
                      alt="exclamation icon"
                    ></img>
                    <span>
                      Once in the app, if you see a screen with subscription
                      plans, simply X out in the top right corner, then login.
                    </span>
                  </div>
                </div>
                <div className="right-confirmation-app-details">
                  <div className="app-images">
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/unplug-meditation/id1188080269#?platform=iphone"
                    >
                      <img src={appStore} class="img-fluid " alt=""></img>
                    </a>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.unplug.meditation"
                    >
                      <img src={playStore} class="img-fluid " alt=""></img>
                    </a>
                  </div>
                  <span>
                    or meditate on{" "}
                    <a href="https://unplug.app/" target="_blank">
                      unplug.app
                    </a>
                  </span>
                </div>
                <div className="gift-form-help">
                  <span>Need Help?</span>{" "}
                  <a
                    className="gift-form-help-link"
                    href="mailto:support@unplug.com"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      // <React.Fragment>
      //   {/* <div class="congratulation-wrapper"> */}
      //   <div class="whole-top-wrapper">
      //     <div class="header">
      //       <div class="container">
      //         <nav class="header-nav navbar navbar-expand-lg navbar-light">
      //           <a
      //             class="brand-logo gift-page navbar-brand"
      //             href="#"
      //             ref="topBar"
      //           >
      //             <img class="img-fluid" src={unplugLogo} />
      //           </a>
      //           <ul class="navbar-nav ml-auto">
      //             <li class="nav-item ">
      //               <a class="nav-link" href="mailto:support@unplug.com">
      //                 Questions? <span>Contact Us</span>{" "}
      //               </a>
      //             </li>
      //           </ul>
      //         </nav>
      //       </div>
      //     </div>

      //     <div class="congrats text-center">
      //       <h3>CONGRATULATIONS</h3>
      //       <p>You now have a subscription to Unplug!</p>
      //       <button class="congrats-btn btn btn-primary">
      //         <a href="https://app.unplug.com/login">start meditating</a>
      //       </button>
      //     </div>

      //     <div class="footer congrats-footer">
      //       <div class="container">
      //         <div class="footer-wrapper d-flex justify-content-between">
      //           <div class="copyright">
      //             <h6 class="copy">Copyright {moment().format("YYYY")}</h6>
      //             <h6>Unplug</h6>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </React.Fragment>
    );
  }
}
