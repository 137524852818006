import {ADD_ALERT, REMOVE_ALERT} from "../constants/types";

let defaultState = {
	show: false, message: "", event: ""
};

export default function (state = defaultState, action) {
	
	switch (action.type) {
		case ADD_ALERT:
			return {...state, show: action.show, message: action.message, event: action.event};
		case REMOVE_ALERT:
			return {...state, show: false};
		default:
			return state;
	}
}