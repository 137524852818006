import {LOGIN, LOGOUT} from '../constants/types';
import Auth from '../actions/Auth';

const tokenCheckMiddleware = store => next => action => {
	let token = window.localStorage.getItem('access_token');
	if (!token) {
		Auth.setToken("");
	}
	next(action);
};

const localStorageMiddleware = store => next => action => {
	if (action.type === LOGIN) {
		if (!action.error) {
			// console.log('yo logged in ', action.payload.access_token)
			// let expiry = moment().format('DD/MM/YYYY HH:mm:ss');
			// expiry = expiry.add(action.payload.expires_in, 'seconds');
			localStorage.setItem('access_token', action.payload.access_token);
			localStorage.setItem('refresh_token', action.payload.refresh_token);
			localStorage.setItem('expiry_time', action.payload.expiry_time);
			Auth.setToken(action.payload.access_token);
			// store.dispatch({type: REDIRECT_TO, redirectTo: '/dashboard'});
		}
	} else if (action.type === LOGOUT) {
		localStorage.setItem('access_token', '');
		localStorage.setItem('refresh_token', '');
		localStorage.clear();
		Auth.setToken(null);
	}
	
	next(action);
};


export {tokenCheckMiddleware, localStorageMiddleware};
