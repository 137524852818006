import {apiUrl} from "../config/Auth";


const API_ROOT = apiUrl


export function oauthApiUrl(url) {
  return `${API_ROOT}${url}`;
}

