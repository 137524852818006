let authKey = {}, apiUrl = "", main_app = "", liveapiURL = "", displayGiftUrl = "", redeemUrl = "", stripekey = {}

if (process.env.REACT_APP_DEPLOYMENT === "dev") {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_DEV),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_DEV
  };
  apiUrl = process.env.REACT_APP_CLIENT_URL_DEV;
  main_app = process.env.REACT_APP_MAIN_APP_URL_DEV;
  liveapiURL = process.env.REACT_APP_APP_URL;
  redeemUrl = process.env.REACT_APP_REDEEM_URL_DEV;
  displayGiftUrl = process.env.REACT_APP_DISPLAY_GIFT_URL_DEV;
  stripekey = process.env.REACT_APP_STRIPE_KEY_DEV;

} else if (process.env.REACT_APP_DEPLOYMENT === "prod") {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_PROD),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_PROD
  };
  apiUrl = process.env.REACT_APP_CLIENT_URL_PROD;
  main_app = process.env.REACT_APP_MAIN_APP_URL_PROD;
  liveapiURL = process.env.REACT_APP_APP_URL;
  redeemUrl = process.env.REACT_APP_REDEEM_URL_PROD;
  displayGiftUrl = process.env.REACT_APP_DISPLAY_GIFT_URL_PROD;
  stripekey = process.env.REACT_APP_STRIPE_KEY_PROD;
} else {
  authKey = {
    "client_id": parseInt(process.env.REACT_APP_CLIENT_ID_QA),
    "client_secret": process.env.REACT_APP_CLIENT_SECRET_QA
  };
  apiUrl = 'https://dev-cms.unplug.app/api/v1/';
  redeemUrl = "https://dev-gift.unplug.app/redeem";
  displayGiftUrl = "https://dev-gift.unplug.app/gift";
  main_app = process.env.REACT_APP_MAIN_APP_URL_QA;
  liveapiURL = process.env.REACT_APP_APP_URL;
}

const GOOGLE_RECAPTCHA = process.env.REACT_APP_RECAPTCHA;
const API_KEY = process.env.REACT_APP_API_KEY;

export {
  authKey, apiUrl,API_KEY, stripekey, main_app, liveapiURL, GOOGLE_RECAPTCHA, redeemUrl, displayGiftUrl
};
