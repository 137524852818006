import React, { Component } from "react";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements";
import { checkError } from "../../../Helper/model";
import Loading from "../../../Layout/Loading";
import Alert from "../../../Alert";
import { GOOGLE_RECAPTCHA } from "../../../../config/Auth";
import moment from "moment";

const defaultState = {
  card_number: "",
  cvc: "",
  expiry: "",
  submitState: false,
  fontSize: "16px",
};
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleChange = (ev) =>
      this.props.onChangeField(ev.target.name, ev.target.value);
    this.handleChangeStripeForm = (ev, name) =>
      this.props.onChangeField(name, ev.target.value);
    this.navigateToConfirmation = (data) => {
      const { recipient_email, recipient_first_name, send_date } = data;
      const parsedDate = moment(send_date, "YYYY-MM-DD");
      const formattedDate = parsedDate.format("MMMM DD, YYYY");
      const encodedDate = encodeURIComponent(formattedDate);
      const encodedValue = encodeURIComponent(recipient_email);
      const { history } = this.props;
      history.push(
        `/confirmation?recipient_email=${encodedValue}&recipient_first_name=${recipient_first_name}&send_date=${encodedDate}`
      );
    };
    this.promoCodeApply = async () => {
      window.scroll(0, 0);
      const data = await this.props.onPromoApply();
      if (!data) {
        this.props.onLoad();
      }
    };
    this.navigateToConfirmation = this.navigateToConfirmation.bind(this);

    this.submitForm = async (ev) => {
      ev.preventDefault();
      this.setState({ ...this.state, submitState: true });
      let { email, card_holder, sender_name, expiry, card_number, cvc } =
        this.props;
      let model = {
        email: {
          value: email,
          key: "email",
          validator: ["isEmail", "isGreaterThanFifty", "isNotEmpty"],
        },
        card_holder: {
          value: card_holder,
          key: "card holder name",
          validator: ["isGreaterThanFifty", "isNotEmpty"],
        },
        sender_name: {
          value: sender_name,
          key: "Sender Name",
          validator: ["isLessThanFive", "isGreaterThanFifty", "isNotEmpty"],
        },
        expiry: {
          value: expiry,
          key: "Expiry Date",
          validator: ["isNotEmpty"],
        },
        cvc: {
          value: cvc,
          key: "Cvc",
          validator: ["isNotEmpty"],
        },
        card_number: {
          value: card_number,
          key: "card holder number",
          validator: [
            "isIncludeNumber",
            "isNotIncludeSpecialCharacter",
            "isGreaterThanFifty",
            "isNotEmpty",
          ],
        },
      };
      let err = checkError(model);
      if (Object.keys(err).length > 0) {
        this.props.onAddValidationError(err);
        this.setState({ ...this.state, submitState: false });
        return;
      }
      await this.props.stripe
        .createToken({
          name: card_holder,
        })
        .then(({ token, error }) => {
          if (error) {
            if (error.type !== "validation_error") {
              let alert = {
                show: true,
                message: error.message,
                event: "danger",
              };
              this.setState((prevState) => ({
                ...prevState,
                submitState: false,
              }));
              this.props.onAddAlert(alert);
            }
          } else {
            function callCaptcha(context, submit) {
              window.grecaptcha.ready(function () {
                window.grecaptcha
                  .execute(GOOGLE_RECAPTCHA, {
                    action: "submit",
                  })
                  .then(async function (recaptchaToken) {
                    context._num_element.clear();
                    context._cvc_element.clear();
                    context._exp_element.clear();
                    const data = await submit.onSubmitTeamRegistration(
                      token.id,
                      recaptchaToken
                    );
                    context.setState((prevState) => ({
                      ...prevState,
                      submitState: false,
                    }));
                    if (data) {
                      submit.clearStripeForm();
                      context.navigateToConfirmation(data);
                      this.setState({ ...this.state, submitState: false });
                      return;
                    }
                    window.scrollTo(0, 0);
                  });
              });
            }
            callCaptcha(this, this.props);

            // let i = 10;
            // setTimeout(() => {
            // 	let int = setInterval(function () {
            // 		window.scrollTo(0, 0);
            // 		i += 45;
            // 		if (i >= 3500) clearInterval(int);
            // 	}, 20);
            // }, 4500)
          }
        });
      this.captureError = this.captureError.bind(this);
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  captureError(ev, key) {
    let error = this.props.error;
    this.props.onAddValidationError(error);
    if (ev.error && ev.error.message) {
      error[key] = ev.error.message;
      this.props.onAddValidationError(error);
      return;
    }
    if (!ev.empty && !ev.error) {
      this.setState({
        [key]: "value",
      });
      this.props.onChangeField([key], "value");
    } else {
      this.props.onChangeField([key], "");
    }
    this.setState({
      update: true,
    });
  }

  componentDidMount() {
    this.handleResize(); // Initial setup

    // Add event listener for window resize
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Cleanup event listener on component unmount
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize() {
    // Adjust font size based on window width
    if (window.innerWidth < 1025) {
      this.setState({ fontSize: "12px" });
    } else if (window.innerWidth < 1441) {
      this.setState({ fontSize: "14px" });
    } else {
      this.setState({ fontSize: "16px" });
    }
  }

  render() {
    const styles = {
      base: {
        fontSize: this.state.fontSize,
        textTransform: "capitalize",
        fontWeight: "100",
        "::placeholder": {
          fontWeight: "100",
          color: "#AFAFAF",
        },
        ":-webkit-autofill": {
          color: "#000000",
        },
        padding: "9px",
      },
      focus: {
        borderColor: "black",
        boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
        outline: "none",
      },
    };
    return (
      <div className="gift-relative-container">
        <Alert />
        <div className="gift-form">
          <div className="gift-form-step2">
            <span className="gift-form-header">Set up payment</span>
            <span className="gift-form-subheader">
              One-time payment for a 1-year plan:
            </span>
            <div className="gift-form-pricing-box">
              <span className="gift-form-pricing">
                ${this.props.rate.new_rate}
              </span>
            </div>
          </div>
          <div className="gift-form-inputs">
            <span className="gift-form-label">Your Email</span>
            <input
              type="text"
              placeholder="Email"
              name="email"
              onChange={this.handleChange}
              value={this.props.email}
            />
            {this.props.checkIfValidationError("email") && (
              <span className="alert-message">
                {this.props.checkIfValidationError("email")}
              </span>
            )}
          </div>
          <div className="gift-form-inputs">
            <span className="gift-form-label">
              Your Name (what the recipient will see)
            </span>
            <input
              type="text"
              placeholder="Name"
              name="sender_name"
              onChange={this.handleChange}
              value={this.props.sender_name}
            />
            {this.props.checkIfValidationError("sender_name") && (
              <span className="alert-message">
                {this.props.checkIfValidationError("sender_name")}
              </span>
            )}
          </div>
          <div className="gift-form-inputs">
            <span className="gift-form-label">Full Name on Card</span>
            <input
              type="text"
              placeholder="Full Name on Card"
              name="card_holder"
              onChange={this.handleChange}
              value={this.props.card_holder}
            />
            {this.props.checkIfValidationError("card_holder") && (
              <span className="alert-message">
                {this.props.checkIfValidationError("card_holder")}
              </span>
            )}
          </div>
          <div className="gift-form-inputs">
            <span className="gift-form-label">Card Number</span>
            <CardNumberElement
              onReady={(element) => (this._num_element = element)}
              style={styles}
              onChange={(ev) => this.captureError(ev, "card_number")}
              className="gift-form-inputs-stripe"
            />
            {this.props.checkIfValidationError("card_number") && (
              <span className="alert-message">
                {this.props.checkIfValidationError("card_number")}
              </span>
            )}
          </div>
          <div className="gift-form-inputs-div">
            <div className="gift-form-inputs">
              <span className="gift-form-label">Expiry Date</span>
              {/* <input
                type="text"
                placeholder="MM/ YY"
                name="expiry"
                onChange={this.handleChange}
                value={this.props.expiry}
              /> */}

              <CardExpiryElement
                onReady={(element) => (this._exp_element = element)}
                style={styles}
                onChange={(ev) => this.captureError(ev, "expiry")}
                className="gift-form-inputs-stripe"
              />
              {this.props.checkIfValidationError("expiry") && (
                <span className="alert-message">
                  {this.props.checkIfValidationError("expiry")}
                </span>
              )}
            </div>
            <div className="gift-form-inputs">
              <span className="gift-form-label">CVC</span>
              {/* <input
                type="text"
                placeholder="Full Name on Card"
                name="cvc"
                onChange={this.handleChange}
                value={this.props.cvc}
              /> */}
              <CardCVCElement
                onReady={(element) => (this._cvc_element = element)}
                style={styles}
                onChange={(ev) => this.captureError(ev, "cvc")}
                className="gift-form-inputs-stripe"
              ></CardCVCElement>
              {this.props.checkIfValidationError("cvc") && (
                <span className="alert-message">
                  {this.props.checkIfValidationError("cvc")}
                </span>
              )}
            </div>
          </div>
          <div className="gift-form-inputs-div">
            <div className="gift-form-inputs">
              <span className="gift-form-label">Promo Code</span>
              <input
                type="text"
                name="promo_code"
                onChange={this.handleChange}
                value={this.props.promo_code}
              />
            </div>
            <div className="gift-form-promo-btn-container">
              {this.props.inProgress && this.props.promo_code !== "" ? (
                <div className="adjust">
                  <div className="loader" />
                </div>
              ) : (
                <button
                  className="gift-form-promo-btn"
                  onClick={this.promoCodeApply}
                  disabled={this.props.promo_code === ""}
                >
                  <span>Apply</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="gift-form-footer">
          {this.state.submitState ? (
            <div className="adjust">
              <div className="loader" />
            </div>
          ) : (
            <button className="gift-form-confirm" onClick={this.submitForm}>
              <span className="gift-form-confirm-text">Purchase Gift</span>
            </button>
          )}

          <div className="gift-form-footer-text">
            By purchasing an Unplug gift card, you agree to our{" "}
            <a href="https://www.unplug.com/terms-of-use" target="_blank">
              Terms & Conditions.
            </a>
          </div>
          <div className="gift-form-help">
            <span>Need Help?</span>{" "}
            <a
              className="gift-form-help-link"
              href="mailto:support@unplug.com"
              target="_blank"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
