import React, { Component } from 'react';
import { auth } from '../../actions';
import { connect } from "react-redux";
import { REDIRECT_CLEAR } from "../../constants/types";
import { routes } from "../../routers";
import NoMatch from '../NotFound/index';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";

const mapStateToProps = state => {
	return {
		appLoaded: state.common.appLoaded,
		appName: state.common.appName,
		currentUser: state.common.currentUser,
		redirectTo: state.common.redirectTo
	};
};


const mapDispatchToProps = dispatch => ({
	onLoad: () =>
		bindActionCreators(auth.fetchProfile, dispatch)(),
	onRedirectClear: () =>
		dispatch({ type: REDIRECT_CLEAR }),
});


class App extends Component {
	didMount = false;

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.didMount) {
			if (this.props.redirectTo && this.props.redirectTo !== prevProps.redirectTo) {
				if (this.props.history) {
					this.props.history.push(this.props.redirectTo);
					this.props.onRedirectClear();
				}

			}
		}
	}

	componentDidMount() {
		this.didMount = true;
		// let token = window.localStorage.getItem('access_token');
		// if (token) {
		// 	auth.setToken(token);
		// }
		// this.props.onLoad();
	}


	render() {

		if (this.props.appLoaded) {
			return (
				<div>
					{/*<Header currentUser={this.props.currentUser}/>*/}
					<Switch>
						{routes.map((route, index) => (
							<Route key={index} path={route.path} component={route.component} exact={route.exact} />
						))}
						<Route component={NoMatch} />
					</Switch>
				</div>
			);
		}

		return (
			<div>Loading.......</div>

		);
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));