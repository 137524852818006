import React, { Component } from "react";
import { connect } from "react-redux";
import { common, teamPortal } from "../../actions";
import { bindActionCreators } from "redux";
import GiftCard from "../../assets/images/gift-card-img.png";
import {
  ADD_ALERT,
  ADD_VALIDATION_ERROR_OBJ,
  CLEAR_STRIPE_FILED,
  UNLOAD_TEAM_PORTAL,
  UPDATE_FIELD_TEAM,
} from "../../constants/types";
// } from "../../../constants/types";
import { withRouter } from "react-router";
import Logo from "../../assets/images/Black-logo.png";

const mapStateToProps = state => ({
  ...state.home,
  ...state.common,
  ...state.alert,
  ...state.teamPortal,
  ...state.validationError,
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => bindActionCreators(teamPortal.fetchRate, dispatch)(),
  onClearStripe: () => dispatch({ type: CLEAR_STRIPE_FILED }),
  onUnLoad: () => dispatch({ type: UNLOAD_TEAM_PORTAL }),
  onSubmitTeamRegistration: (submitTeamForm, recaptchaToken) =>
    bindActionCreators(teamPortal.submitTeamForm, dispatch)(
      submitTeamForm,
      recaptchaToken
    ),
  onAddValidationError: data =>
    dispatch({ type: ADD_VALIDATION_ERROR_OBJ, error: data }),
  onAddAlert: alert =>
    dispatch({
      type: ADD_ALERT,
      show: alert.show,
      message: alert.message,
      event: alert.event,
    }),
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_TEAM, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});
class Confirmation extends Component {
  constructor(props) {
    super(props);
  }
  handleButtonClick = () => {
    this.props.history.push("/");
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const email = searchParams.get("recipient_email");
    const decodedEmail = decodeURIComponent(email);
    const name = searchParams.get("recipient_first_name");
    const date = searchParams.get("send_date");
    return (
      <React.Fragment>
        <div className="confirmation-container">
          <div className="gift-logo-sm">
            <img className="img-fluid" src={Logo} />
          </div>
          <div>
            <div className="confirmation-box-container">
              <h1>Your gift is on its way💙</h1>
              <h2>
                Woohoo! Your gift of a 1-year Unplug subscription is on the way
                to {name} at {decodedEmail}
              </h2>
              <img className="img-fluid" src={GiftCard} alt="gift card" />
              <span className="gift-card-span">
                They will get it in their inbox on {date}, along with your gift
                message so they will know who to thank. Thank you for spreading
                peace & Unplug.
              </span>
              <a className="gift-form-confirm" href="/" target="_blank">
                <span className="gift-form-confirm-text">
                  Send Another Gift
                </span>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Confirmation)
);
