import axios from 'axios';
import Auth from "../actions/Auth";

axios.interceptors.response.use( (response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        localStorage.setItem('access_token', "");
        localStorage.setItem('refresh_token', "");
        localStorage.setItem('expiry_time', "");
        Auth.setToken("");
    }
    return Promise.reject(error);
});