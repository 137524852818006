import {APP_LOAD, ASYNC_END, ASYNC_START, LOGOUT, REDIRECT_CLEAR, REDIRECT_TO} from '../constants/types';

const defaultState = {
    appName: 'My test React App',
    token: null,
    viewChangeCounter: 0,
    appLoaded: true
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                appLoaded: true,
                currentUser: action.payload ? action.payload.data : null
            };
        case LOGOUT:
            return {
                token: action.token,
                currentUser: action.currentUser,
                appLoaded: true,
                show: false,
                message: null,
                event: null
            };
        case REDIRECT_CLEAR:
            return {...state, redirectTo: null};
        case REDIRECT_TO:
            return {...state, redirectTo: action.redirectTo};
        case ASYNC_START:
            return {...state, inProgress: true};
        case ASYNC_END:
            return {...state, inProgress: false};
        default:
            return state;
    }
};
