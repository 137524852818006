import Signup from "../components/Auth/Signup";
import PageLoader from "../components/Hoc/PageLoader";
import Pdf from "../components/Auth/Pdf";
import Confirmation from "../components/Confirmation";
import TeamPortal from "../components/PublicModules/TeamPortal";

export const routes = [
  {
    path: "/",
    component: PageLoader(TeamPortal),
    exact: true,
  },
  {
    path: "/redeem",
    component: PageLoader(Signup),
    exact: true,
  },
  {
    path: "/download",
    component: PageLoader(Pdf),
    exact: true,
  },
  {
    path: "/confirmation",
    component: PageLoader(Confirmation),
    exact: true,
  },
];
