import React, { Component } from 'react'
import unplugLogo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom'

export default class NoMatch extends Component {
    render() {
        return (
            <div>
                <div className="body-contents">
                    <div className="header">
                        <div className="container error">
                            <div className="header-inner">
                                <div className="logo-div">
                                    <Link to='/' ><img className="img-fluid error-logo" src={unplugLogo} alt="" /></Link>
                                </div>
                                <div className="contact-div">
                                    <p className="mb-0">
                                        Question? <a href="mailto:support@unplug.com">Contact us.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contents-main">
                        <div className="container">
                            <div className="error-div">
                                <div className="error-div-inner">
                                    <h1>404</h1>
                                    <h2>"Something went wrong"</h2>
                                    <Link to='/' ><button className="btn go-home__btn">Go to homepage</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
