import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import loaderImage from '../../../assets/images/spinner.gif';


const mapStateToProps = state => {
	return {
		appLoaded: state.common.appLoaded,
		...state.validationError
	};
};

const mapDispatchToProps = dispatch => ({
	onLoad: () => {
	},
});

export default function (ComposedComponent) {
	class PageLoader extends Component {
		
		constructor(props) {
			super(props);
			this.checkIfValidationError = this.checkIfValidationError.bind(this);
			this.checkIfErrorExists = this.checkIfErrorExists.bind(this);
		}
		
		checkIfValidationError(key) {
			if (this.props.error && this.props.error.hasOwnProperty(key)) {
				let err = this.props.error[key];
				return "*" + err;
			}
			return null;
		}
		
		checkIfErrorExists(key) {
			if (this.props.error && this.props.error.hasOwnProperty(key)) {
				return "error-alert";
			}
			return "";
		}
		
		render() {
			if (this.props.appLoaded) {
				
				return <ComposedComponent
					checkIfValidationError={this.checkIfValidationError}
					checkIfErrorExists={this.checkIfErrorExists}
				/>;
			}
			return <div className='loaderDiv'><img alt="loader" className='loaderImg' src={loaderImage}/></div>;
			
		}
	}
	
	return withRouter(connect(mapStateToProps, mapDispatchToProps)(PageLoader));
}