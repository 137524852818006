import {
	LOGIN,
	LOGIN_PAGE_UNLOADED,
	REGISTER,
	REGISTER_PAGE_UNLOADED,
	UPDATE_FIELD_AUTH,
	USER_CAPACITY_FULL
} from '../constants/types';

let defaultState = {
	email: "",
	password: "",
	coupon: "",
	first_name: "",
	last_name: "",
	agreement: false,
	registerSuccessFul: false,
	full: false,
	team_name: ""
	
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case LOGIN_PAGE_UNLOADED:
		case REGISTER_PAGE_UNLOADED:
			return {};
		case UPDATE_FIELD_AUTH:
			return {...state, [action.key]: action.value};
		case LOGIN:
			return {
				...state,
				token: action.payload.access_token,
				inProgress: false,
				errors: action.error ? 'Invalid username/password!' : null
			};
		case REGISTER:
			return {
				...state,
				inProgress: false
			};
		
		case USER_CAPACITY_FULL:
			return {
				...state,
				full: action.payload
			};
		default:
			return state;
	}
};
