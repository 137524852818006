import React, { Component } from "react";
import { connect } from "react-redux";
import { auth, common } from "../../../actions";
import { bindActionCreators } from "redux";
import {
  REGISTER_PAGE_UNLOADED,
  UPDATE_FIELD_AUTH,
} from "../../../constants/types";
import Loading from "../../Layout/Loading";
import { withRouter } from "react-router";
import borderStraight from "../../../assets/images/border-straight.png";
import giftLogo from "../../../assets/images/giftLogo.png";
import appStore from "../../../assets/images/gift-app-apple.png";
import playStore from "../../../assets/images/gift-app-google.png";
import giftPhoneImg from "../../../assets/images/giftPhoneImg.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const mapStateToProps = (state) => ({
  ...state.home,
  ...state.alert,
  ...state.auth,
  ...state.common,
  ...state.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => { },
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
  onSubmit: () => {
    bindActionCreators(auth.registerUser, dispatch)();
  },
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_AUTH, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});

class Pdf extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.onLoad();
  }

  componentDidMount() {
    let coupon = this.props.location.hash;
    setTimeout(() => {
      html2canvas(document.querySelector(".foryou-download")).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", [652.339, 435]);
        pdf.addImage(imgData, "PNG", 0, 0, 230, 0);
        pdf.save("Unplug Gift Certificate.pdf");
      });
    }, 1500);
    if (coupon) {
      this.props.onChangeField("coupon", coupon.substr(1));
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    let { coupon } = this.props;
    return (
      <div
        className="top-foryou-download"
        style={{
          width: "875px",
          margin: "auto",
          position: "relative",
          fontFamily: "sf pro display",
        }}
      >
        <div
          className="foryou-download"
          style={{
            width: "875px",
            height: "584px",
            border: " 9px solid #88C4EC",
          }}
        >
          {/* <img src={background} className="pdf-image" style={{ maxWidth: '900px' }} /> */}
          <div
            className="foryou-wrapper-download"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              position: "absolute",
              top: "0",
              width: "100%",
              padding: "30px 60px",
            }}
          >
            <div
              className="inner-div-download inner-div-media"
              style={{
                maxWidth: "66%",
                margin: "auto",
                padding: "4px",
                marginTop: "0",
              }}
            >
              <div className="gift-logo-div">
                <div className="gift-logo">
                  <img src={giftLogo} className="img-fluid"></img>
                </div>
              </div>
              <div className="gift">
                <h6
                  style={{
                    color: "#1A1919",
                    fontSize: "42px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  a gift<span style={{}}> for you</span>
                </h6>
                <p
                  style={{
                    fontSize: "34px",
                    marginTop: "15px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#1A1919",
                    fontWeight: "600",
                  }}
                >
                  Enjoy a 1-year subscription to the unplug meditation app
                </p>
              </div>

              <div
                className="takeagift text-center"
                style={{ position: "relative" }}
              >
                <img
                  src={borderStraight}
                  className="pdf-border"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div
                  className="xyz"
                  style={{
                    backgroundColor: " #88C4EC",
                    border: "none",
                    padding: "18px 31px",
                    borderRadius: "14px",
                    maxWidth: "310px",
                    margin: "auto",
                  }}
                >
                  <p
                    className=""
                    style={{
                      fontSize: "15px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontWeight: "400",
                      marginBottom: "10px",
                      lineHeight: "20px",
                    }}
                  >
                    To redeem your gift, visit{" "}
                    <a
                      href="https://gift.unplug.app/redeem"
                      style={{ color: "#FFFFFF", textDecoration: "underline" }}
                      target="_blank"
                    >
                      unplug.app/gift
                    </a>{" "}
                    and enter the code:
                  </p>
                  {this.props.inProgress ? (
                    <div className="loader-bottom">
                      <Loading inProgress={this.props.inProgress} />
                    </div>
                  ) : (
                    <input
                      value={coupon}
                      readOnly="readonly"
                      className="codeplaceholder"
                      style={{
                        border: "0",
                        outline: "0",
                        width: "95%",
                        background: "#ffffff",
                        fontWeight: "700",
                        fontSize: "24px",
                        padding: "5px 10px",
                        margin: "auto",
                        color: "#1A1919 ",
                        textAlign: "center",
                        borderRadius: "6px",
                      }}
                    ></input>
                  )}
                </div>
              </div>
              <div
                className="giftcard-storelink d-flex justify-content-center"
                style={{ paddingTop: "0px", marginTop: "35px" }}
              >
                <div
                  className="google-app"
                  style={{
                    marginRight: "25px",
                    maxWidth: "170px",
                    backgroundColor: "white",
                    borderRadius: "9px",
                  }}
                >
                  <img src={appStore} alt="" style={{}} className="img-fluid" />
                </div>
                <div
                  className="store d-flex"
                  style={{
                    maxWidth: "170px",
                    backgroundColor: "white",
                    borderRadius: "9px",
                  }}
                >
                  <img src={playStore} alt="" className="img-fluid" />
                </div>
              </div>
            </div>

            <div
              className="inner-div-download inner-div-media"
              style={{ maxWidth: "33%", margin: "auto", padding: "15px" }}
            >
              <div className="giftImgDiv" style={{ padding: "0px" }}>
                <img src={giftPhoneImg} className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pdf));
