import {
  CLEAR_STRIPE_FILED,
  LOAD_RATE,
  SET_COUPON,
  UNLOAD_TEAM_PORTAL,
  UPDATE_FIELD_TEAM,
} from "../constants/types";

let defaultState = {
  recipient_first_name: "",
  recipient_last_name: "",
  email: "",
  send_date: "",
  note: "",
  recipient_email: "",
  card_holder: "",
  card_number: "",
  cvc: "",
  expiry: "",
  promo_code: "",
  rate: {},
  maximum_users: "1",
  coupon: "",
  sender_name: "",
};

//   {
//     "name": "siya",
//     "email": "mailto:siya-gift@mailinator.com",
//     "password": "admin123",
//     "card_holder": "xien",
//     "card_token": "tok_visa",
//     "zip_code": "111",
//     "maximum_users": "2",
//     "full_name" : "ashim",
//     "recipient_first_name" : "ashim",
//     "recipient_last_name" : "rajbhandari",
//      "recipient_email" : "mailto:ashim@gmail.com",
//      "send_date": "2023-10-10",
//      "note": "Hello",
//      "coupon":"ashim"
// }
// let defaultState = {
// 	email: "",
// 	password: "",
// 	name: "",
// 	maximum_users: 1,
// 	card_holder: "",
// 	zip_code: "",
// 	rate: {},
// 	per_year: 0,
// 	coupon: ""

// };

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_FIELD_TEAM:
      return { ...state, [action.key]: action.value };
    case UNLOAD_TEAM_PORTAL:
      return {};
    case LOAD_RATE:
      return { ...state, rate: action.payload || {} };
    case SET_COUPON:
      return { ...state, coupon: action.payload || "" };
    case CLEAR_STRIPE_FILED:
      return {
        ...state,
        email: "",
        card_holder: "",
        recipient_first_name: "",
        recipient_last_name: "",
        send_date: "",
        note: "",
        recipient_email: "",
        card_number: "",
        cvc: "",
        expiry: "",
        promo_code: "",
        maximum_users: "1",
        coupon: "",
        sender_name: "",
      };
      break;
    default:
      return state;
  }
};
