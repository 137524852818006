import React, { Component } from "react";
import { connect } from "react-redux";
import { auth, common } from "../../../actions";
import { bindActionCreators } from "redux";
import {
  REGISTER_PAGE_UNLOADED,
  UPDATE_FIELD_AUTH,
} from "../../../constants/types";
import Loading from "../../Layout/Loading";
import Kickoff from "./Kickoff";
import Alert from "../../Alert";
import { withRouter } from "react-router";
import GiftForYou from "../../../assets/images/gift-for-you.jpeg";

import Logo from "../../../assets/images/Black-logo.png";

const mapStateToProps = (state) => ({
  ...state.home,
  ...state.alert,
  ...state.auth,
  ...state.common,
  ...state.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {},
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
  onSubmit: () => {
    bindActionCreators(auth.registerUser, dispatch)();
  },
  onChangeField: (key, value) => {
    dispatch({ type: UPDATE_FIELD_AUTH, key, value });
    bindActionCreators(common.deleteValidationErrorAttribute, dispatch)(key);
  },
});
const defaultState = {
  isCouponInUrl: false,
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleChange = (ev) => {
      let value;
      if (ev.target.type === "checkbox") {
        value = ev.target.checked;
      } else {
        value = ev.target.value;
      }
      this.props.onChangeField(ev.target.name, value);
    };
    this.submitForm = (ev) => {
      ev.preventDefault();
      this.props.onSubmit();
    };
  }

  componentWillMount() {
    this.props.onLoad();
    const searchParams = new URLSearchParams(this.props.location.search);
    const code = searchParams.get("redeem_code");
    if (code !== null) {
      this.setState({ isCouponInUrl: true });
      this.props.onChangeField("coupon", code);
    } else {
      this.setState({ isCouponInUrl: false });
    }
  }

  componentDidMount() {
    // let coupon = this.props.location.hash;
    // if (coupon) {
    //   this.props.onChangeField("coupon", coupon.substr(1));
    // }
    // const searchParams = new URLSearchParams(this.props.location.search);
    // const code = searchParams.get("redeem_code");
    // this.props.onChangeField("coupon", code);
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    let { email, password, coupon, agreement, registerSuccessFul, full } =
      this.props;
    if (registerSuccessFul || full) {
      return <Kickoff full={full} team_name="asdfasdf" />;
    }
    return (
      <React.Fragment>
        <div className="gift-grid">
          <div className="gift-grid-column-left">
            <div className="container-gift-grid">
              <div className="gift-content-container">
                <div className="gift-logo-div">
                  <div className="gift-logo-sm">
                    <a href="/">
                      <img className="img-fluid" src={Logo} />
                    </a>
                  </div>
                </div>
                <div className="gift-text-div">
                  <span className="gift-text-lg">
                    Redeem your Unplug gift card
                  </span>
                  <span className="gift-text-sm">
                    Experience the benefits of of meditation anytime, anywhere.
                    Feel inspired by the world's top teachers, and enjoy peace
                    in your pocket.
                  </span>
                  <div className="gift-for-you">
                    <img
                      src={GiftForYou}
                      alt="A gift for you with unplug logo"
                    />
                  </div>
                  <div className="gift-subscription">
                    <span className="gift-subscription-header">
                      Enjoy your 1-year subscription
                      <br /> to the Unplug app
                    </span>
                    {/* <span className="gift-subscription-text">
                      $69.99 USD. One-time payment.
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gift-grid-column-right gift-grid-form-background">
            <div className="container-gift-grid-form">
              <>
                <div className="gift-form">
                  <Alert />
                  <span className="gift-form-header">
                    Create your unplug account
                  </span>
                  <div className="gift-form-inputs">
                    <span className="gift-form-label">Coupon Code</span>
                    <input
                      type="text"
                      placeholder="Coupon Code"
                      name="coupon"
                      onChange={this.handleChange}
                      value={this.props.coupon ? this.props.coupon : ""}
                      disabled={this.state.isCouponInUrl}
                    />
                    {this.props.checkIfValidationError("coupon") && (
                      <span className="alert-message">
                        {this.props.checkIfValidationError("coupon")}
                      </span>
                    )}
                  </div>
                  <div className="gift-form-inputs-div">
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">First Name</span>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        onChange={this.handleChange}
                        value={this.props.first_name}
                      />
                      {this.props.checkIfValidationError("first_name") && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError("first_name")}
                        </span>
                      )}
                    </div>
                    <div className="gift-form-inputs">
                      <span className="gift-form-label">Last Name</span>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        onChange={this.handleChange}
                        value={this.props.last_name}
                      />
                      {this.props.checkIfValidationError("last_name") && (
                        <span className="alert-message">
                          {this.props.checkIfValidationError("last_name")}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="gift-form-inputs">
                    <span className="gift-form-label">Email</span>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={this.handleChange}
                      value={this.props.email}
                    />
                    {this.props.checkIfValidationError("email") && (
                      <span className="alert-message">
                        {this.props.checkIfValidationError("email")}
                      </span>
                    )}
                  </div>
                  <div className="gift-form-inputs">
                    <span className="gift-form-label">Password</span>
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={this.handleChange}
                      value={this.props.password}
                    />
                    {this.props.checkIfValidationError("password") && (
                      <span className="alert-message">
                        {this.props.checkIfValidationError("password")}
                      </span>
                    )}
                  </div>
                  <div
                    className={`policy-section ${this.props.checkIfErrorExists(
                      "agreement"
                    )}`}
                  >
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={agreement}
                      onChange={this.handleChange}
                    />
                    <div
                      className={`${this.props.checkIfErrorExists(
                        "agreement"
                      )}`}
                    >
                      <label>
                        By checking this box, you agree to Unplug's &nbsp;
                        <a
                          target={"_blank"}
                          href="https://www.unplug.com/terms-of-use"
                        >
                          <strong>Terms and Conditions &nbsp;</strong>
                        </a>
                        and &nbsp;
                        <a
                          target={"_blank"}
                          href="https://www.unplug.com/privacy-policy"
                        >
                          <strong>Privacy Policy</strong>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="gift-form-confirm-div">
                  {this.props.inProgress ? (
                    <div className="adjust">
                      <div className="loader" />
                    </div>
                  ) : (
                    <button
                      className="gift-form-confirm"
                      onClick={this.submitForm}
                    >
                      <span className="gift-form-confirm-text">
                        Redeem Your Gift
                      </span>
                    </button>
                  )}
                </div>
                <div className="gift-form-help">
                  <span>Need Help?</span>{" "}
                  <a
                    className="gift-form-help-link"
                    href="mailto:support@unplug.com"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </div>
              </>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
