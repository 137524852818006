import { oauthApiUrl } from "../../services/api";
import {
  ADD_VALIDATION_ERROR_OBJ,
  APP_LOAD,
  LOGIN,
  UPDATE_FIELD_AUTH,
} from "../../constants/types";
import axios from "axios";
import { asyncEnd, asyncStart, dajngoValidationError } from "../Common";
import { checkError } from "../../components/Helper/model";
import { API_KEY } from "../../config/Auth";

export function setAccessToken(data) {
  return {
    type: LOGIN,
    payload: data,
    error: !data,
  };
}

export function setProfile(data) {
  tokenPlugin();
  return {
    type: APP_LOAD,
    payload: data.data,
    appLoaded: true,
  };
}

let token;

const tokenPlugin = () => {
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {};
};

export function addValidationError(data) {
  return {
    type: ADD_VALIDATION_ERROR_OBJ,
    error: data,
  };
}

const registerUser = () => (dispatch, getState) => {
  dispatch(asyncStart());
  let { email, password, coupon, agreement, first_name, last_name } =
    getState().auth;
  let model = {
    email: {
      value: email,
      key: "email",
      validator: ["isEmail", "isNotEmpty"],
    },
    password: {
      value: password,
      key: "password",
      validator: ["isNotEmpty"],
    },
    first_name: {
      value: first_name,
      key: "First Name",
      validator: ["isGreaterThanFifty", "isNotEmpty"],
    },
    last_name: {
      value: last_name,
      key: "Last Name",
      validator: ["isGreaterThanFifty", "isNotEmpty"],
    },
    coupon: {
      value: coupon,
      validator: ["isGreaterThanFifty", "isNotEmpty"],
    },
    agreement: {
      value: agreement,
      key: "agreement",
      validator: ["isTrue"],
    },
  };
  let err = checkError(model);
  if (Object.keys(err).length > 0) {
    dispatch(addValidationError(err));
    dispatch(asyncEnd());
    return;
  }
  const url = oauthApiUrl("auth/gift/register");
  // let url = 'https://cms.unplug.app/api/v1/auth/gift/register';
  let config = {
    headers: {
      "api-key": API_KEY,
    },
  };
  return axios
    .post(
      `${url}`,
      { email, password, coupon, agreement, first_name, last_name },
      config
    )
    .then(({ data }) => {
      dispatch({
        type: UPDATE_FIELD_AUTH,
        key: "registerSuccessFul",
        value: true,
      });
      dispatch(asyncEnd());
    })
    .catch((err) => {
      dajngoValidationError(err, dispatch);
      dispatch(asyncEnd());
    });
};

export default {
  registerUser,
  setToken: (_token) => {
    token = _token;
  },
};
