import {ADD_ALERT} from "../../constants/types";

export function addAlertMessage (message) {
    return {
        type: ADD_ALERT,
        show: message.show,
        message: message.message,
        event: message.event
    }
}

export function removeAlertMessage (message){
    return {
        type: ADD_ALERT,
        show: false
    }
}
