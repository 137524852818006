import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {localStorageMiddleware, tokenCheckMiddleware} from './middleware';
import createRootReducer from './reducers'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'



// Build the middleware for intercepting and dispatching navigation actions
export const history = createBrowserHistory();

const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
    if (process.env.REACT_APP_DEPLOYMENT === 'production') {
        return applyMiddleware(myRouterMiddleware, myRouterMiddleware, thunk, tokenCheckMiddleware, localStorageMiddleware);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(myRouterMiddleware, myRouterMiddleware, thunk, tokenCheckMiddleware, localStorageMiddleware)
    }
};

export const store = createStore(
    createRootReducer(history), composeWithDevTools(getMiddleware()));