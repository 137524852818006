import { oauthApiUrl } from "../../services/api";
import axios from "axios";
import { LOAD_RATE, SET_COUPON } from "../../constants/types";
import { asyncEnd, asyncStart, dajngoValidationError } from "../Common";
import { addAlertMessage } from "../AlertMessage";
import { API_KEY } from "../../config/Auth";

let config = {
  headers: {
    "api-key": API_KEY,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
};

const fetchRate = () => (dispatch, getState) => {
  let url = oauthApiUrl("billing/gift/subscription-rate");
  // let url = 'https://cms.unplug.app/api/v1/billing/gift/subscription-rate';
  return axios
    .get(url, config)
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: LOAD_RATE, payload: data.data });
      }
    })
    .catch((err) => {
      dajngoValidationError(err, dispatch);
    });
};

const submitTeamForm = (card_token, recaptchaToken) => (dispatch, getState) => {
  let url = oauthApiUrl("billing/gift/subscription");
  // let url = "https://dev-cms.unplug.app/api/v2/billing/gift/subscription";
  let {
    email,
    maximum_users,
    card_holder,
    recipient_first_name,
    recipient_last_name,
    recipient_email,
    send_date,
    note,
    sender_name,
    promo_code,
  } = getState().teamPortal;
  return axios
    .post(
      url,
      {
        name: card_holder,
        email,
        password: "password", //no ui components so hardcoded
        zip_code: "111", //no ui components so hardcoded
        card_holder,
        card_token,
        maximum_users,
        full_name: `${recipient_first_name} ${recipient_last_name}`,
        recaptchaToken,
        recipient_first_name: recipient_first_name,
        recipient_last_name: recipient_last_name,
        recipient_email,
        send_date,
        note,
        coupon: promo_code,
        sender_name,
      },
      config
    )
    .then(({ data }) => {
      dispatch({ type: SET_COUPON, payload: data.coupon });
      let alert = {
        show: true,
        message:
          "Your gift purchase was successful! Please check your email for the gift certificate PDF to pass along to the recipient.",
        event: "success",
      };
      // dispatch(addAlertMessage(alert));
      return data;
    })
    .catch((err) => {
      dajngoValidationError(err, dispatch);
    });
};
const applyPromo = () => (dispatch, getState) => {
  let url = oauthApiUrl("billing/gift/apply-coupon-purchase");
  let { promo_code } = getState().teamPortal;
  dispatch(asyncStart());
  return axios
    .post(
      url,
      {
        maximum_users: "1",
        coupon: promo_code,
      },
      config
    )
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: LOAD_RATE,
          payload: {
            old_rate: 59.99,
            new_rate: data.rate,
          },
        });
        let alert = {
          show: true,
          message: "Your Promo Code has been Successfully added!",
          event: "success",
        };
        dispatch(addAlertMessage(alert));
        dispatch(asyncEnd());
        return data;
      }
    })
    .catch((err) => {
      dajngoValidationError(err, dispatch);
      dispatch(asyncEnd());
    });
};

export default {
  fetchRate,
  submitTeamForm,
  applyPromo,
};
